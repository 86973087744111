a img {
  display: block;
}

body figure {
  margin: 0;
}

pre {
  display: inline-block;
  margin: 0;
  white-space: normal;
  word-break: break-all;

  &.xdebug-var-dump {
    white-space: pre;
  }
}

ul {
  margin-top: 0;
}

ol li {
  margin-bottom: 10px;
}

ul li {
  position: relative;
}

ol.list-unstyled li::before {
  display: none;
}

table {
  border: none;
  margin-bottom: 20px;
}

table th,
td {
  vertical-align: top;
}

table th {
  text-align: left;
}
