@mixin hide {
  position: absolute !important;
  left: -9000px !important;
}

@mixin displayFlex($flexDirection: null, $alignItems: null, $justifyContent: null, $alignSelf: null, $flexWrap: null) {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: $flexDirection;
  flex-direction: $flexDirection;
  -webkit-align-items: $alignItems;
  align-items: $alignItems;
  -webkit-justify-content: $justifyContent;
  justify-content: $justifyContent;
  align-self: $alignSelf;
  flex-wrap: $flexWrap;
}

@mixin vertical-align-middle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin standard-border-radius {
  border-radius: 2px;
}

@mixin reset-button {
  outline: none;
  border: none;
  background: none;
  box-shadow: none;
}

@mixin rounded-corners {
  border-radius: $border-radius;
}

@mixin rounded-corners-small {
  border-radius: $border-radius-sm;
}

@mixin tool-button-color {
  background: $primary;
  border-color: $primary;
}

@mixin triangle-block {
  position: relative;
  box-sizing: border-box;
  background: $white;
  box-shadow: 0 0 2px $gray-400, 0 2px 2px 1px $gray-400;
}
