@import '../../../defaults';
@import 'forms-include';
@import 'images';
@import 'typography';

.anw-navbar-2021 {
  border-bottom: none !important;
}

.anw-bg-internal {
  background-color: #fff1d1;
}

.anw-mw-50 {
  max-width: 50%;
}

html,
body {
  height: 100%;
}

.clear {
  clear: both;
}

.left {
  float: left;
}

.right,
img.right {
  float: right;
}

.center {
  text-align: center;
}

.anw-clickable {
  cursor: pointer;
}

.anw-disable-pointer-event {
  pointer-events: none;

  a {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: none;
  }
}

.anw-no-js {
  .anw-show-if-js {
    display: none;
  }

  .anw-show-if-no-js {
    display: block !important;
  }
}

a {
  &.text {
    color: $body-color;
    font-weight: $font-weight-normal;
    text-decoration: none;
  }
}

.anw-break-text {
  @include break-text;
}

aside {
  float: left;
}

.relatedPracticeAreas {
  margin: 18px 0 26px 0;
  @include secondary-body-font;
}

.anw-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.anw-toggle-wrapper {
  .collapsing {
    transition: none;
    display: none;
  }
  .show {
    & + .anw-toggle-box {
      .anw-toggle-dots,
      .anw-toggle-more {
        display: none;
      }
      .anw-toggle-less {
        display: inline;
      }
    }
  }
  .anw-toggle-less {
    display: none;
  }
}
