@import '../../../defaults';

::-webkit-input-placeholder {
  color: $gray-450;
}

:-moz-placeholder {
  color: $gray-450;
  opacity: 1;
}

::-moz-placeholder {
  color: $gray-450;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $gray-450;
}

[type='checkbox'] {
  float: left;
  margin: 0.27em 7px 0 0;
}

[type='checkbox'] + label {
  display: inline;
}

form .checkboxLabel {
  overflow: hidden;
  display: inline;
}

label + label,
label + label + label {
  margin-left: 30px;
}

input[type='radio'] {
  margin: 4px 4px 0 0;
}

input[type='checkbox'] {
  margin: 4px 4px 0 0;
}

.form {
  .file {
    float: left;
    width: auto;
    margin: 0 5px 0 0;
    font: 1em Arial;
  }
  .meta {
    clear: both;
    .hint {
      display: inline-block;
      margin-left: 170px;
    }
  }
  .day {
    padding: 2px 5px;
    width: 32px;
  }
  .month {
    width: 80px;
    padding: 2px 5px;
  }
  .year {
    width: 64px;
    padding: 2px 5px;
  }
  .fileupload {
    input.width1 {
      width: 290px;
    }
  }
  label {
    &.hint {
      clear: both;
      display: block;
      width: auto;
      padding: 0;
      margin: 0;
    }
    &.price {
      @include media-breakpoint-down(md) {
        height: 26px;
      }
    }
  }
  fieldset {
    @include media-breakpoint-down(xs) {
      box-sizing: border-box;
      border: none;
    }
    @include media-breakpoint-down(md) {
      box-sizing: border-box;
      border: none;
    }
    &.fieldsetError {
      @include media-breakpoint-down(md) {
        padding: 15px 15px 10px;
      }
    }
  }
  button.button,
  button.button.left,
  button.button.right,
  .formItem input.text,
  textarea,
  .front + textarea,
  label.front,
  label.top,
  label.after {
    @include media-breakpoint-down(md) {
      float: none;
      margin-left: 0;
      width: 100%;
    }
  }

  textarea {
    @include media-breakpoint-down(xs) {
      float: none;
      width: 100%;
      margin-left: 0;
    }
    @include media-breakpoint-down(ms) {
      float: left;
    }
  }
  button {
    &.button {
      @include media-breakpoint-down(xs) {
        margin-bottom: 10px;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
    }
  }
  .actionText {
    @include media-breakpoint-down(md) {
      height: 46px;
      width: 58px;
      margin-bottom: 0;
    }
  }

  .input-error > input {
    @include media-breakpoint-down(md) {
      height: 42px;
    }
  }
}

.emailItem .actionText {
  width: 300px;
}

// Custom error messages
// https://test.v4.anwalt.de/meinkonto/anwalt/konto/benachrichtigungen
.form-errors {
  color: #c00;
}

aside .form .formItem {
  margin-bottom: 10px;
}

input[data-ajax] {
  &::-ms-reveal,
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
}

@import '../../components/forms/formFieldStatus';
