.anw-text-disabled {
  color: $gray-400;
}

p {
  margin: 0 0 1rem 0;

  &.standout {
    font-size: 1.125rem;
  }
}

.caption {
  font-size: $font-size-sm;
}

.caption {
  color: $gray-550;
}

hr {
  border: 0;
  border-top: 1px solid $gray-400;
  clear: both;
  margin-bottom: 10px;
  margin-top: 10px;
}

.anw-emphasize-text {
  font-size: 150%;
}

h1 .anw-font-sm {
  line-height: 1;
}
