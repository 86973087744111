@import '../../../defaults';

@media print {
  * {
    overflow: visible !important;
  }

  html {
    body {
      background: $white;
      margin: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  .container {
    margin: 0 !important;
    padding: 0 !important;
    max-width: none !important;
    min-width: unset !important;
    width: 100% !important;
  }

  @page {
    margin: 0.25in;
    size: 8.5in 11in;
  }

  .anw-logo {
    margin-bottom: map-get($spacers, 4);
  }

  .anw-print-avoid-page-break,
  figure,
  table {
    break-inside: avoid;
  }

  h2,
  h3 {
    break-after: avoid-page;
  }

  p {
    orphans: 2;
    widows: 2;
  }
}
