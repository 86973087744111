@charset "UTF-8";
dd, legend {
  margin-bottom: 0.5rem;
}

label, output {
  display: inline-block;
}

progress, sub, sup {
  vertical-align: baseline;
}

.ratio, .ratio > *, legend {
  width: 100%;
}

.fixed-top, .ratio > *, .sticky-top {
  top: 0;
}

.fixed-bottom, .sticky-bottom {
  bottom: 0;
}

.vr, hr {
  opacity: 0.25;
}

dl, ol, p, pre, ul {
  margin-top: 0;
}

[class*=" anw-list-"], [class^=anw-list-], ol {
  list-style: none;
}

[class*=" anw-list-"] li, [class^=anw-list-] li, ol li {
  margin-bottom: 10px;
}

.ratio, ol li, sub, sup {
  position: relative;
}

.anw-hover {
  transition: background-color 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .anw-hover {
    transition: none;
  }
}
.anw-hover:hover {
  background-color: #f0f0f0 !important;
}

.anw-hover:hover .anw-hover-contrast {
  background-color: #fff !important;
}

.bg-cyan {
  --anw-bg-opacity:1;
  background-color: rgba(1, 203, 217, var(--anw-bg-opacity));
}

.bg-gray-550 {
  --anw-bg-opacity:1;
  background-color: rgba(133, 133, 133, var(--anw-bg-opacity));
}

.bg-gray-100 {
  --anw-bg-opacity:1;
  background-color: rgba(249, 249, 249, var(--anw-bg-opacity));
}

:root {
  --anw-blue:#385273;
  --anw-indigo:#6610f2;
  --anw-purple:#6f42c1;
  --anw-pink:#d63384;
  --anw-red:#990000;
  --anw-orange:#e95d0f;
  --anw-yellow:#ff9600;
  --anw-green:#0cb949;
  --anw-teal:#0cb949;
  --anw-cyan:#01cbd9;
  --anw-black:#000;
  --anw-white:#fff;
  --anw-gray:#666666;
  --anw-gray-dark:#5c5c5c;
  --anw-gray-150:#f6f6f6;
  --anw-gray-350:#ebebeb;
  --anw-gray-400:#c2c2c2;
  --anw-gray-450:#adadad;
  --anw-gray-550:#858585;
  --anw-primary:#e95d0f;
  --anw-secondary:#0cb949;
  --anw-success:#38734b;
  --anw-info:#385273;
  --anw-warning:#ff9600;
  --anw-danger:#990000;
  --anw-light:#f0f0f0;
  --anw-dark:#333;
  --anw-primary-rgb:233,93,15;
  --anw-secondary-rgb:12,185,73;
  --anw-success-rgb:56,115,75;
  --anw-info-rgb:56,82,115;
  --anw-warning-rgb:255,150,0;
  --anw-danger-rgb:153,0,0;
  --anw-light-rgb:240,240,240;
  --anw-dark-rgb:51,51,51;
  --anw-white-rgb:255,255,255;
  --anw-black-rgb:0,0,0;
  --anw-body-color-rgb:51,51,51;
  --anw-body-bg-rgb:255,255,255;
  --anw-font-sans-serif:"Open Sans","Segoe UI",Tahoma,sans-serif;
  --anw-font-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --anw-gradient:linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --anw-body-font-family:var(--anw-font-sans-serif);
  --anw-body-font-size:1rem;
  --anw-body-font-weight:400;
  --anw-body-line-height:1.375;
  --anw-body-color:#333;
  --anw-body-bg:#fff;
  --anw-border-width:1px;
  --anw-border-style:solid;
  --anw-border-color:#c2c2c2;
  --anw-border-color-translucent:rgba(0, 0, 0, 0.175);
  --anw-border-radius:4px;
  --anw-border-radius-sm:2px;
  --anw-border-radius-lg:10px;
  --anw-border-radius-xl:1rem;
  --anw-border-radius-2xl:2rem;
  --anw-border-radius-pill:50rem;
  --anw-link-color:#e95d0f;
  --anw-link-hover-color:inherit;
  --anw-code-color:#d63384;
  --anw-highlight-bg:#ffeacc;
}

*, ::after, ::before {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: var(--anw-body-font-family);
  font-size: var(--anw-body-font-size);
  font-weight: var(--anw-body-font-weight);
  line-height: var(--anw-body-line-height);
  color: var(--anw-body-color);
  text-align: var(--anw-body-text-align);
  background-color: var(--anw-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

a > code, hr, pre code {
  color: inherit;
}

hr {
  margin: 1rem 0;
  border: 0;
  border-top: 1px solid #c2c2c2;
  opacity: initial;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 300;
  line-height: 1.375;
  color: #385273;
}

address, dl, ol, p, pre, ul {
  margin-bottom: 1rem;
}

blockquote, figure {
  margin: 0 0 1rem;
}

address, legend {
  line-height: inherit;
}

.h1, h1 {
  font-size: var(--anw-h1-font-size);
}

.h2, h2 {
  font-size: var(--anw-h2-font-size);
}

.h3, h3 {
  font-size: var(--anw-h3-font-size);
}

.h4, h4 {
  font-size: var(--anw-h4-font-size);
}

.h5, h5 {
  font-size: 1.125rem;
}

.h6, h6 {
  font-size: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

.anw-link-heading:hover, a, a:hover {
  text-decoration: underline;
}

[role=button], [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled), summary {
  cursor: pointer;
}

address {
  font-style: normal;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-left: 0;
}

.small, small {
  font-size: 87.5%;
}

.mark, mark {
  padding: 0.1875em;
  background-color: var(--anw-highlight-bg);
}

ol, ol li {
  padding-left: 0.6em;
}

sub, sup {
  font-size: 0.75em;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--anw-link-color);
}

a:hover {
  color: var(--anw-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code, kbd, pre, samp {
  font-family: var(--anw-font-monospace);
  font-size: 1em;
}

code, kbd, pre {
  font-size: 87.5%;
}

pre {
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  word-break: normal;
}

code {
  color: var(--anw-code-color);
  word-wrap: break-word;
}

kbd {
  padding: 0.1875rem 0.375rem;
  color: var(--anw-body-bg);
  background-color: var(--anw-body-color);
  border-radius: 2px;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #666;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

tbody, td, tfoot, th, thead, tr {
  border: 0 solid;
  border-color: inherit;
}

button {
  border-radius: 0;
}

fieldset, iframe {
  border: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
}

legend {
  float: left;
  padding: 0;
  font-size: calc(1.275rem + .3vw);
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

summary {
  display: list-item;
}

[hidden] {
  display: none !important;
}

.hstack, .vstack {
  display: flex;
  align-self: stretch;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(233, 93, 15, var(--anw-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(12, 185, 73, var(--anw-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(56, 115, 75, var(--anw-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(56, 82, 115, var(--anw-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 150, 0, var(--anw-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(153, 0, 0, var(--anw-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(240, 240, 240, var(--anw-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(51, 51, 51, var(--anw-bg-opacity, 1)) !important;
}

.link-primary {
  color: #e95d0f !important;
}

.link-primary:focus, .link-primary:hover {
  color: #ed7d3f !important;
}

.link-secondary {
  color: #0cb949 !important;
}

.link-secondary:focus, .link-secondary:hover {
  color: #3dc76d !important;
}

.link-success {
  color: #38734b !important;
}

.link-success:focus, .link-success:hover {
  color: #2d5c3c !important;
}

.link-info {
  color: #385273 !important;
}

.link-info:focus, .link-info:hover {
  color: #2d425c !important;
}

.link-warning {
  color: #ff9600 !important;
}

.link-warning:focus, .link-warning:hover {
  color: #ffab33 !important;
}

.link-danger {
  color: #900 !important;
}

.link-danger:focus, .link-danger:hover {
  color: #7a0000 !important;
}

.link-light {
  color: #f0f0f0 !important;
}

.link-light:focus, .link-light:hover {
  color: #f3f3f3 !important;
}

.link-dark {
  color: #333 !important;
}

.link-dark:focus, .link-dark:hover {
  color: #292929 !important;
}

.ratio::before {
  display: block;
  padding-top: var(--anw-aspect-ratio);
  content: "";
}

.anw-bullet-list li:first-child i, .collapse:not(.show), ol.list-unstyled li::before {
  display: none;
}

.ratio > * {
  position: absolute;
  left: 0;
  height: 100%;
}

.fixed-bottom, .fixed-top {
  position: fixed;
  z-index: 1030;
  right: 0;
  left: 0;
}

.ratio-1x1 {
  --anw-aspect-ratio:100%;
}

.ratio-4x3 {
  --anw-aspect-ratio:calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --anw-aspect-ratio:calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --anw-aspect-ratio:calc(9 / 21 * 100%);
}

.sticky-bottom, .sticky-top {
  position: sticky;
  z-index: 1020;
}

@media (min-width: 468px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1024px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }

  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1600px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  flex-direction: row;
  align-items: center;
}

.vstack {
  flex: 1 1 auto;
  flex-direction: column;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 0.15rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-none, a.anw-plain {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--anw-border-width) var(--anw-border-style) var(--anw-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--anw-border-width) var(--anw-border-style) var(--anw-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--anw-border-width) var(--anw-border-style) var(--anw-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--anw-border-width) var(--anw-border-style) var(--anw-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--anw-border-width) var(--anw-border-style) var(--anw-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --anw-border-opacity:1;
  border-color: rgba(var(--anw-primary-rgb), var(--anw-border-opacity)) !important;
}

.border-secondary {
  --anw-border-opacity:1;
  border-color: rgba(var(--anw-secondary-rgb), var(--anw-border-opacity)) !important;
}

.border-success {
  --anw-border-opacity:1;
  border-color: rgba(var(--anw-success-rgb), var(--anw-border-opacity)) !important;
}

.border-info {
  --anw-border-opacity:1;
  border-color: rgba(var(--anw-info-rgb), var(--anw-border-opacity)) !important;
}

.border-warning {
  --anw-border-opacity:1;
  border-color: rgba(var(--anw-warning-rgb), var(--anw-border-opacity)) !important;
}

.border-danger {
  --anw-border-opacity:1;
  border-color: rgba(var(--anw-danger-rgb), var(--anw-border-opacity)) !important;
}

.border-light {
  --anw-border-opacity:1;
  border-color: rgba(var(--anw-light-rgb), var(--anw-border-opacity)) !important;
}

.border-dark {
  --anw-border-opacity:1;
  border-color: rgba(var(--anw-dark-rgb), var(--anw-border-opacity)) !important;
}

.border-white {
  --anw-border-opacity:1;
  border-color: rgba(var(--anw-white-rgb), var(--anw-border-opacity)) !important;
}

.border-1 {
  --anw-border-width:1px;
}

.border-2 {
  --anw-border-width:2px;
}

.border-3 {
  --anw-border-width:3px;
}

.border-4 {
  --anw-border-width:4px;
}

.border-5 {
  --anw-border-width:5px;
}

.border-opacity-10 {
  --anw-border-opacity:0.1;
}

.border-opacity-25 {
  --anw-border-opacity:0.25;
}

.border-opacity-50 {
  --anw-border-opacity:0.5;
}

.border-opacity-75 {
  --anw-border-opacity:0.75;
}

.border-opacity-100 {
  --anw-border-opacity:1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-8 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-10 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.mt-8 {
  margin-top: 3.5rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.me-8 {
  margin-right: 3.5rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.mb-8 {
  margin-bottom: 3.5rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.ms-8 {
  margin-left: 3.5rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 4.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n6 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n7 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n8 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n9 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n10 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n7 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n8 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n10 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n6 {
  margin-top: -2.5rem !important;
}

.mt-n7 {
  margin-top: -3rem !important;
}

.mt-n8 {
  margin-top: -3.5rem !important;
}

.mt-n9 {
  margin-top: -4rem !important;
}

.mt-n10 {
  margin-top: -4.5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n6 {
  margin-right: -2.5rem !important;
}

.me-n7 {
  margin-right: -3rem !important;
}

.me-n8 {
  margin-right: -3.5rem !important;
}

.me-n9 {
  margin-right: -4rem !important;
}

.me-n10 {
  margin-right: -4.5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n6 {
  margin-bottom: -2.5rem !important;
}

.mb-n7 {
  margin-bottom: -3rem !important;
}

.mb-n8 {
  margin-bottom: -3.5rem !important;
}

.mb-n9 {
  margin-bottom: -4rem !important;
}

.mb-n10 {
  margin-bottom: -4.5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n6 {
  margin-left: -2.5rem !important;
}

.ms-n7 {
  margin-left: -3rem !important;
}

.ms-n8 {
  margin-left: -3.5rem !important;
}

.ms-n9 {
  margin-left: -4rem !important;
}

.ms-n10 {
  margin-left: -4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-8 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-10 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pe-8 {
  padding-right: 3.5rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 3.5rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.ps-8 {
  padding-left: 3.5rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 4.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 2.5rem !important;
}

.gap-7 {
  gap: 3rem !important;
}

.gap-8 {
  gap: 3.5rem !important;
}

.gap-9 {
  gap: 4rem !important;
}

.gap-10 {
  gap: 4.5rem !important;
}

.font-monospace {
  font-family: var(--anw-font-monospace) !important;
}

.fs-1 {
  font-size: var(--anw-h1-font-size) !important;
}

.fs-2 {
  font-size: var(--anw-h2-font-size) !important;
}

.fs-3 {
  font-size: var(--anw-h3-font-size) !important;
}

.fs-4 {
  font-size: var(--anw-h4-font-size) !important;
}

.fs-5 {
  font-size: 1.125rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold, .fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.375 !important;
}

.lh-lg {
  line-height: 1.5 !important;
}

.display-1, .display-2, .display-3, .display-4, .display-5, .display-6 {
  font-weight: 300;
  line-height: 1.375;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --anw-text-opacity:1;
  color: rgba(var(--anw-primary-rgb), var(--anw-text-opacity)) !important;
}

.text-secondary {
  --anw-text-opacity:1;
  color: rgba(var(--anw-secondary-rgb), var(--anw-text-opacity)) !important;
}

.text-success {
  --anw-text-opacity:1;
  color: rgba(var(--anw-success-rgb), var(--anw-text-opacity)) !important;
}

.text-info {
  --anw-text-opacity:1;
  color: rgba(var(--anw-info-rgb), var(--anw-text-opacity)) !important;
}

.text-warning {
  --anw-text-opacity:1;
  color: rgba(var(--anw-warning-rgb), var(--anw-text-opacity)) !important;
}

.text-danger {
  --anw-text-opacity:1;
  color: rgba(var(--anw-danger-rgb), var(--anw-text-opacity)) !important;
}

.text-light {
  --anw-text-opacity:1;
  color: rgba(var(--anw-light-rgb), var(--anw-text-opacity)) !important;
}

.text-dark {
  --anw-text-opacity:1;
  color: rgba(var(--anw-dark-rgb), var(--anw-text-opacity)) !important;
}

.text-black {
  --anw-text-opacity:1;
  color: rgba(var(--anw-black-rgb), var(--anw-text-opacity)) !important;
}

.text-white {
  --anw-text-opacity:1;
  color: rgba(var(--anw-white-rgb), var(--anw-text-opacity)) !important;
}

.text-body {
  --anw-text-opacity:1;
  color: rgba(var(--anw-body-color-rgb), var(--anw-text-opacity)) !important;
}

.text-muted {
  --anw-text-opacity:1;
  color: #666 !important;
}

.text-black-50 {
  --anw-text-opacity:1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --anw-text-opacity:1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --anw-text-opacity:1;
  color: inherit !important;
}

.text-opacity-25 {
  --anw-text-opacity:0.25;
}

.text-opacity-50 {
  --anw-text-opacity:0.5;
}

.text-opacity-75 {
  --anw-text-opacity:0.75;
}

.text-opacity-100 {
  --anw-text-opacity:1;
}

.bg-primary {
  --anw-bg-opacity:1;
  background-color: rgba(var(--anw-primary-rgb), var(--anw-bg-opacity)) !important;
}

.bg-secondary {
  --anw-bg-opacity:1;
  background-color: rgba(var(--anw-secondary-rgb), var(--anw-bg-opacity)) !important;
}

.bg-success {
  --anw-bg-opacity:1;
  background-color: rgba(var(--anw-success-rgb), var(--anw-bg-opacity)) !important;
}

.bg-info {
  --anw-bg-opacity:1;
  background-color: rgba(var(--anw-info-rgb), var(--anw-bg-opacity)) !important;
}

.bg-warning {
  --anw-bg-opacity:1;
  background-color: rgba(var(--anw-warning-rgb), var(--anw-bg-opacity)) !important;
}

.bg-danger {
  --anw-bg-opacity:1;
  background-color: rgba(var(--anw-danger-rgb), var(--anw-bg-opacity)) !important;
}

.bg-light {
  --anw-bg-opacity:1;
  background-color: rgba(var(--anw-light-rgb), var(--anw-bg-opacity)) !important;
}

.bg-dark {
  --anw-bg-opacity:1;
  background-color: rgba(var(--anw-dark-rgb), var(--anw-bg-opacity)) !important;
}

.bg-black {
  --anw-bg-opacity:1;
  background-color: rgba(var(--anw-black-rgb), var(--anw-bg-opacity)) !important;
}

.bg-white {
  --anw-bg-opacity:1;
  background-color: rgba(var(--anw-white-rgb), var(--anw-bg-opacity)) !important;
}

.bg-body {
  --anw-bg-opacity:1;
  background-color: rgba(var(--anw-body-bg-rgb), var(--anw-bg-opacity)) !important;
}

.bg-transparent {
  --anw-bg-opacity:1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --anw-bg-opacity:0.1;
}

.bg-opacity-25 {
  --anw-bg-opacity:0.25;
}

.bg-opacity-50 {
  --anw-bg-opacity:0.5;
}

.bg-opacity-75 {
  --anw-bg-opacity:0.75;
}

.bg-opacity-100 {
  --anw-bg-opacity:1;
}

.bg-gradient {
  background-image: var(--anw-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--anw-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--anw-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--anw-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--anw-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--anw-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--anw-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--anw-border-radius-pill) !important;
}

.rounded-end, .rounded-top {
  border-top-right-radius: var(--anw-border-radius) !important;
}

.rounded-bottom, .rounded-end {
  border-bottom-right-radius: var(--anw-border-radius) !important;
}

.rounded-bottom, .rounded-start {
  border-bottom-left-radius: var(--anw-border-radius) !important;
}

.rounded-start, .rounded-top {
  border-top-left-radius: var(--anw-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 468px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 2rem !important;
  }

  .m-sm-6 {
    margin: 2.5rem !important;
  }

  .m-sm-7 {
    margin: 3rem !important;
  }

  .m-sm-8 {
    margin: 3.5rem !important;
  }

  .m-sm-9 {
    margin: 4rem !important;
  }

  .m-sm-10 {
    margin: 4.5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 2rem !important;
  }

  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 3rem !important;
  }

  .mt-sm-8 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-9 {
    margin-top: 4rem !important;
  }

  .mt-sm-10 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 2rem !important;
  }

  .me-sm-6 {
    margin-right: 2.5rem !important;
  }

  .me-sm-7 {
    margin-right: 3rem !important;
  }

  .me-sm-8 {
    margin-right: 3.5rem !important;
  }

  .me-sm-9 {
    margin-right: 4rem !important;
  }

  .me-sm-10 {
    margin-right: 4.5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 2rem !important;
  }

  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 3rem !important;
  }

  .ms-sm-8 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-9 {
    margin-left: 4rem !important;
  }

  .ms-sm-10 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -2rem !important;
  }

  .m-sm-n6 {
    margin: -2.5rem !important;
  }

  .m-sm-n7 {
    margin: -3rem !important;
  }

  .m-sm-n8 {
    margin: -3.5rem !important;
  }

  .m-sm-n9 {
    margin: -4rem !important;
  }

  .m-sm-n10 {
    margin: -4.5rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-sm-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -2rem !important;
  }

  .mt-sm-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -3rem !important;
  }

  .mt-sm-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n9 {
    margin-top: -4rem !important;
  }

  .mt-sm-n10 {
    margin-top: -4.5rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -2rem !important;
  }

  .me-sm-n6 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -3rem !important;
  }

  .me-sm-n8 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n9 {
    margin-right: -4rem !important;
  }

  .me-sm-n10 {
    margin-right: -4.5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -4.5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -2rem !important;
  }

  .ms-sm-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -3rem !important;
  }

  .ms-sm-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n9 {
    margin-left: -4rem !important;
  }

  .ms-sm-n10 {
    margin-left: -4.5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 2rem !important;
  }

  .p-sm-6 {
    padding: 2.5rem !important;
  }

  .p-sm-7 {
    padding: 3rem !important;
  }

  .p-sm-8 {
    padding: 3.5rem !important;
  }

  .p-sm-9 {
    padding: 4rem !important;
  }

  .p-sm-10 {
    padding: 4.5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 2rem !important;
  }

  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 3rem !important;
  }

  .pt-sm-8 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-9 {
    padding-top: 4rem !important;
  }

  .pt-sm-10 {
    padding-top: 4.5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 2rem !important;
  }

  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 3rem !important;
  }

  .pe-sm-8 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-9 {
    padding-right: 4rem !important;
  }

  .pe-sm-10 {
    padding-right: 4.5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 2rem !important;
  }

  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 3rem !important;
  }

  .ps-sm-8 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-9 {
    padding-left: 4rem !important;
  }

  .ps-sm-10 {
    padding-left: 4.5rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 2rem !important;
  }

  .gap-sm-6 {
    gap: 2.5rem !important;
  }

  .gap-sm-7 {
    gap: 3rem !important;
  }

  .gap-sm-8 {
    gap: 3.5rem !important;
  }

  .gap-sm-9 {
    gap: 4rem !important;
  }

  .gap-sm-10 {
    gap: 4.5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 2rem !important;
  }

  .m-md-6 {
    margin: 2.5rem !important;
  }

  .m-md-7 {
    margin: 3rem !important;
  }

  .m-md-8 {
    margin: 3.5rem !important;
  }

  .m-md-9 {
    margin: 4rem !important;
  }

  .m-md-10 {
    margin: 4.5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 2rem !important;
  }

  .mt-md-6 {
    margin-top: 2.5rem !important;
  }

  .mt-md-7 {
    margin-top: 3rem !important;
  }

  .mt-md-8 {
    margin-top: 3.5rem !important;
  }

  .mt-md-9 {
    margin-top: 4rem !important;
  }

  .mt-md-10 {
    margin-top: 4.5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 2rem !important;
  }

  .me-md-6 {
    margin-right: 2.5rem !important;
  }

  .me-md-7 {
    margin-right: 3rem !important;
  }

  .me-md-8 {
    margin-right: 3.5rem !important;
  }

  .me-md-9 {
    margin-right: 4rem !important;
  }

  .me-md-10 {
    margin-right: 4.5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2rem !important;
  }

  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 3rem !important;
  }

  .mb-md-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 4rem !important;
  }

  .mb-md-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 2rem !important;
  }

  .ms-md-6 {
    margin-left: 2.5rem !important;
  }

  .ms-md-7 {
    margin-left: 3rem !important;
  }

  .ms-md-8 {
    margin-left: 3.5rem !important;
  }

  .ms-md-9 {
    margin-left: 4rem !important;
  }

  .ms-md-10 {
    margin-left: 4.5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -2rem !important;
  }

  .m-md-n6 {
    margin: -2.5rem !important;
  }

  .m-md-n7 {
    margin: -3rem !important;
  }

  .m-md-n8 {
    margin: -3.5rem !important;
  }

  .m-md-n9 {
    margin: -4rem !important;
  }

  .m-md-n10 {
    margin: -4.5rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-md-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -2rem !important;
  }

  .mt-md-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -3rem !important;
  }

  .mt-md-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n9 {
    margin-top: -4rem !important;
  }

  .mt-md-n10 {
    margin-top: -4.5rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -2rem !important;
  }

  .me-md-n6 {
    margin-right: -2.5rem !important;
  }

  .me-md-n7 {
    margin-right: -3rem !important;
  }

  .me-md-n8 {
    margin-right: -3.5rem !important;
  }

  .me-md-n9 {
    margin-right: -4rem !important;
  }

  .me-md-n10 {
    margin-right: -4.5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -4.5rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -2rem !important;
  }

  .ms-md-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -3rem !important;
  }

  .ms-md-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n9 {
    margin-left: -4rem !important;
  }

  .ms-md-n10 {
    margin-left: -4.5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 2rem !important;
  }

  .p-md-6 {
    padding: 2.5rem !important;
  }

  .p-md-7 {
    padding: 3rem !important;
  }

  .p-md-8 {
    padding: 3.5rem !important;
  }

  .p-md-9 {
    padding: 4rem !important;
  }

  .p-md-10 {
    padding: 4.5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 2rem !important;
  }

  .pt-md-6 {
    padding-top: 2.5rem !important;
  }

  .pt-md-7 {
    padding-top: 3rem !important;
  }

  .pt-md-8 {
    padding-top: 3.5rem !important;
  }

  .pt-md-9 {
    padding-top: 4rem !important;
  }

  .pt-md-10 {
    padding-top: 4.5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 2rem !important;
  }

  .pe-md-6 {
    padding-right: 2.5rem !important;
  }

  .pe-md-7 {
    padding-right: 3rem !important;
  }

  .pe-md-8 {
    padding-right: 3.5rem !important;
  }

  .pe-md-9 {
    padding-right: 4rem !important;
  }

  .pe-md-10 {
    padding-right: 4.5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2rem !important;
  }

  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 3rem !important;
  }

  .pb-md-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-9 {
    padding-bottom: 4rem !important;
  }

  .pb-md-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 2rem !important;
  }

  .ps-md-6 {
    padding-left: 2.5rem !important;
  }

  .ps-md-7 {
    padding-left: 3rem !important;
  }

  .ps-md-8 {
    padding-left: 3.5rem !important;
  }

  .ps-md-9 {
    padding-left: 4rem !important;
  }

  .ps-md-10 {
    padding-left: 4.5rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 2rem !important;
  }

  .gap-md-6 {
    gap: 2.5rem !important;
  }

  .gap-md-7 {
    gap: 3rem !important;
  }

  .gap-md-8 {
    gap: 3.5rem !important;
  }

  .gap-md-9 {
    gap: 4rem !important;
  }

  .gap-md-10 {
    gap: 4.5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1024px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 2rem !important;
  }

  .m-lg-6 {
    margin: 2.5rem !important;
  }

  .m-lg-7 {
    margin: 3rem !important;
  }

  .m-lg-8 {
    margin: 3.5rem !important;
  }

  .m-lg-9 {
    margin: 4rem !important;
  }

  .m-lg-10 {
    margin: 4.5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 2rem !important;
  }

  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 3rem !important;
  }

  .mt-lg-8 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-9 {
    margin-top: 4rem !important;
  }

  .mt-lg-10 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 2rem !important;
  }

  .me-lg-6 {
    margin-right: 2.5rem !important;
  }

  .me-lg-7 {
    margin-right: 3rem !important;
  }

  .me-lg-8 {
    margin-right: 3.5rem !important;
  }

  .me-lg-9 {
    margin-right: 4rem !important;
  }

  .me-lg-10 {
    margin-right: 4.5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 2rem !important;
  }

  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 3rem !important;
  }

  .ms-lg-8 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-9 {
    margin-left: 4rem !important;
  }

  .ms-lg-10 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -2rem !important;
  }

  .m-lg-n6 {
    margin: -2.5rem !important;
  }

  .m-lg-n7 {
    margin: -3rem !important;
  }

  .m-lg-n8 {
    margin: -3.5rem !important;
  }

  .m-lg-n9 {
    margin: -4rem !important;
  }

  .m-lg-n10 {
    margin: -4.5rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-lg-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -2rem !important;
  }

  .mt-lg-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -3rem !important;
  }

  .mt-lg-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n9 {
    margin-top: -4rem !important;
  }

  .mt-lg-n10 {
    margin-top: -4.5rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -2rem !important;
  }

  .me-lg-n6 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -3rem !important;
  }

  .me-lg-n8 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n9 {
    margin-right: -4rem !important;
  }

  .me-lg-n10 {
    margin-right: -4.5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -4.5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -2rem !important;
  }

  .ms-lg-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -3rem !important;
  }

  .ms-lg-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n9 {
    margin-left: -4rem !important;
  }

  .ms-lg-n10 {
    margin-left: -4.5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 2rem !important;
  }

  .p-lg-6 {
    padding: 2.5rem !important;
  }

  .p-lg-7 {
    padding: 3rem !important;
  }

  .p-lg-8 {
    padding: 3.5rem !important;
  }

  .p-lg-9 {
    padding: 4rem !important;
  }

  .p-lg-10 {
    padding: 4.5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 2rem !important;
  }

  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 3rem !important;
  }

  .pt-lg-8 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-9 {
    padding-top: 4rem !important;
  }

  .pt-lg-10 {
    padding-top: 4.5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 2rem !important;
  }

  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 3rem !important;
  }

  .pe-lg-8 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-9 {
    padding-right: 4rem !important;
  }

  .pe-lg-10 {
    padding-right: 4.5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 2rem !important;
  }

  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 3rem !important;
  }

  .ps-lg-8 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-9 {
    padding-left: 4rem !important;
  }

  .ps-lg-10 {
    padding-left: 4.5rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 2rem !important;
  }

  .gap-lg-6 {
    gap: 2.5rem !important;
  }

  .gap-lg-7 {
    gap: 3rem !important;
  }

  .gap-lg-8 {
    gap: 3.5rem !important;
  }

  .gap-lg-9 {
    gap: 4rem !important;
  }

  .gap-lg-10 {
    gap: 4.5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 2rem !important;
  }

  .m-xl-6 {
    margin: 2.5rem !important;
  }

  .m-xl-7 {
    margin: 3rem !important;
  }

  .m-xl-8 {
    margin: 3.5rem !important;
  }

  .m-xl-9 {
    margin: 4rem !important;
  }

  .m-xl-10 {
    margin: 4.5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 2rem !important;
  }

  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 3rem !important;
  }

  .mt-xl-8 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-9 {
    margin-top: 4rem !important;
  }

  .mt-xl-10 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 2rem !important;
  }

  .me-xl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xl-7 {
    margin-right: 3rem !important;
  }

  .me-xl-8 {
    margin-right: 3.5rem !important;
  }

  .me-xl-9 {
    margin-right: 4rem !important;
  }

  .me-xl-10 {
    margin-right: 4.5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 2rem !important;
  }

  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 3rem !important;
  }

  .ms-xl-8 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-9 {
    margin-left: 4rem !important;
  }

  .ms-xl-10 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -2rem !important;
  }

  .m-xl-n6 {
    margin: -2.5rem !important;
  }

  .m-xl-n7 {
    margin: -3rem !important;
  }

  .m-xl-n8 {
    margin: -3.5rem !important;
  }

  .m-xl-n9 {
    margin: -4rem !important;
  }

  .m-xl-n10 {
    margin: -4.5rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xl-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -3rem !important;
  }

  .mt-xl-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n9 {
    margin-top: -4rem !important;
  }

  .mt-xl-n10 {
    margin-top: -4.5rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -2rem !important;
  }

  .me-xl-n6 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -3rem !important;
  }

  .me-xl-n8 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n9 {
    margin-right: -4rem !important;
  }

  .me-xl-n10 {
    margin-right: -4.5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -4.5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xl-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -3rem !important;
  }

  .ms-xl-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n9 {
    margin-left: -4rem !important;
  }

  .ms-xl-n10 {
    margin-left: -4.5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 2rem !important;
  }

  .p-xl-6 {
    padding: 2.5rem !important;
  }

  .p-xl-7 {
    padding: 3rem !important;
  }

  .p-xl-8 {
    padding: 3.5rem !important;
  }

  .p-xl-9 {
    padding: 4rem !important;
  }

  .p-xl-10 {
    padding: 4.5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 2rem !important;
  }

  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 3rem !important;
  }

  .pt-xl-8 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-9 {
    padding-top: 4rem !important;
  }

  .pt-xl-10 {
    padding-top: 4.5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 2rem !important;
  }

  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 3rem !important;
  }

  .pe-xl-8 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-9 {
    padding-right: 4rem !important;
  }

  .pe-xl-10 {
    padding-right: 4.5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 2rem !important;
  }

  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 3rem !important;
  }

  .ps-xl-8 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-9 {
    padding-left: 4rem !important;
  }

  .ps-xl-10 {
    padding-left: 4.5rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 2rem !important;
  }

  .gap-xl-6 {
    gap: 2.5rem !important;
  }

  .gap-xl-7 {
    gap: 3rem !important;
  }

  .gap-xl-8 {
    gap: 3.5rem !important;
  }

  .gap-xl-9 {
    gap: 4rem !important;
  }

  .gap-xl-10 {
    gap: 4.5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 2rem !important;
  }

  .m-xxl-6 {
    margin: 2.5rem !important;
  }

  .m-xxl-7 {
    margin: 3rem !important;
  }

  .m-xxl-8 {
    margin: 3.5rem !important;
  }

  .m-xxl-9 {
    margin: 4rem !important;
  }

  .m-xxl-10 {
    margin: 4.5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 2rem !important;
  }

  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 3rem !important;
  }

  .mt-xxl-8 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-9 {
    margin-top: 4rem !important;
  }

  .mt-xxl-10 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 2rem !important;
  }

  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 3rem !important;
  }

  .me-xxl-8 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-9 {
    margin-right: 4rem !important;
  }

  .me-xxl-10 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 2rem !important;
  }

  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 3rem !important;
  }

  .ms-xxl-8 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-9 {
    margin-left: 4rem !important;
  }

  .ms-xxl-10 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -2rem !important;
  }

  .m-xxl-n6 {
    margin: -2.5rem !important;
  }

  .m-xxl-n7 {
    margin: -3rem !important;
  }

  .m-xxl-n8 {
    margin: -3.5rem !important;
  }

  .m-xxl-n9 {
    margin: -4rem !important;
  }

  .m-xxl-n10 {
    margin: -4.5rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -4.5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -2rem !important;
  }

  .me-xxl-n6 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -3rem !important;
  }

  .me-xxl-n8 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n9 {
    margin-right: -4rem !important;
  }

  .me-xxl-n10 {
    margin-right: -4.5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -4.5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -4.5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 2rem !important;
  }

  .p-xxl-6 {
    padding: 2.5rem !important;
  }

  .p-xxl-7 {
    padding: 3rem !important;
  }

  .p-xxl-8 {
    padding: 3.5rem !important;
  }

  .p-xxl-9 {
    padding: 4rem !important;
  }

  .p-xxl-10 {
    padding: 4.5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 2rem !important;
  }

  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 3rem !important;
  }

  .pt-xxl-8 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-9 {
    padding-top: 4rem !important;
  }

  .pt-xxl-10 {
    padding-top: 4.5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 2rem !important;
  }

  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 3rem !important;
  }

  .pe-xxl-8 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-9 {
    padding-right: 4rem !important;
  }

  .pe-xxl-10 {
    padding-right: 4.5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 2rem !important;
  }

  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 3rem !important;
  }

  .ps-xxl-8 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-9 {
    padding-left: 4rem !important;
  }

  .ps-xxl-10 {
    padding-left: 4.5rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 2rem !important;
  }

  .gap-xxl-6 {
    gap: 2.5rem !important;
  }

  .gap-xxl-7 {
    gap: 3rem !important;
  }

  .gap-xxl-8 {
    gap: 3.5rem !important;
  }

  .gap-xxl-9 {
    gap: 4rem !important;
  }

  .gap-xxl-10 {
    gap: 4.5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.list-inline-item, ol li::before {
  display: inline-block;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}
.list-inline, .list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 87.5%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.2625rem + .15vw);
}

@media (min-width: 1200px) {
  .blockquote {
    font-size: 1.375rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 87.5%;
  color: #666;
}

.blockquote-footer::before {
  content: "— ";
}

.small, small {
  font-weight: 400;
}

.h1, h1 {
  color: var(--anw-h1-color);
  font-weight: var(--anw-h1-font-weight);
  line-height: var(--anw-h1-line-height);
}

.anw-custom-heading .h1, .anw-custom-heading h1, .h1.anw-custom-heading, h1.anw-custom-heading {
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .h1, h1 {
    font-size: var(--anw-h1-font-size-lg);
    font-weight: var(--anw-h1-font-weight-lg);
    line-height: var(--anw-h1-line-heigt-lg);
  }

  .anw-custom-heading .h1, .anw-custom-heading h1, .h1.anw-custom-heading, h1.anw-custom-heading {
    font-size: 2.625rem;
    font-weight: 400;
  }
}
.h2, h2 {
  color: var(--anw-h2-color);
  font-weight: var(--anw-h2-font-weight);
  line-height: var(--anw-h2-line-height);
}

.anw-custom-heading .h2, .anw-custom-heading h2, .h2.anw-custom-heading, h2.anw-custom-heading {
  color: #333;
  font-size: 1.375rem;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .h2, h2 {
    font-size: var(--anw-h2-font-size-lg);
    font-weight: var(--anw-h2-font-weight-lg);
    line-height: var(--anw-h2-line-heigt-lg);
  }

  .anw-custom-heading .h2, .anw-custom-heading h2, .h2.anw-custom-heading, h2.anw-custom-heading {
    font-size: 2rem;
  }
}
.h3, h3 {
  color: var(--anw-h3-color);
  font-weight: var(--anw-h3-font-weight);
  line-height: var(--anw-h3-line-height);
}

.anw-custom-heading .h3, .anw-custom-heading h3, .h3.anw-custom-heading, h3.anw-custom-heading {
  color: #333;
  font-size: 1.25rem;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .h3, h3 {
    font-size: var(--anw-h3-font-size-lg);
    font-weight: var(--anw-h3-font-weight-lg);
    line-height: var(--anw-h3-line-height-lg);
  }

  .anw-custom-heading .h3, .anw-custom-heading h3, .h3.anw-custom-heading, h3.anw-custom-heading {
    font-size: 1.625rem;
  }
}
.h4, h4 {
  color: var(--anw-h4-color);
  font-weight: var(--anw-h4-font-weight);
  line-height: var(--anw-h4-line-height);
}

.anw-custom-heading .h4, .anw-custom-heading h4, .h4.anw-custom-heading, h4.anw-custom-heading {
  font-size: 1.125rem;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .h4, h4 {
    font-size: var(--anw-h4-font-size-lg);
    font-weight: var(--anw-h4-font-weight-lg);
    line-height: var(--anw-h4-line-height-lg);
  }

  .anw-custom-heading .h4, .anw-custom-heading h4, .h4.anw-custom-heading, h4.anw-custom-heading {
    font-size: 1.375rem;
  }
}
.h5, h5 {
  color: #333;
  font-weight: 400;
}

.anw-link-heading {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}

.anw-link-heading:hover {
  background-color: transparent;
  color: #333;
}

.anw-link-dark {
  color: #333;
  font-weight: 400;
}

.anw-link-dark:hover {
  background-color: #f0f0f0;
  color: #333;
  font-weight: 400;
}

.anw-link-blue, .anw-link-blue:hover {
  color: #385273;
  font-weight: 400;
}

a {
  color: var(--anw-link-color);
  font-weight: var(--anw-link-font-weight);
  text-decoration: var(--anw-link-text-decoration);
}

a:hover {
  background-color: var(--anw-link-hover-background-color);
  color: var(--anw-link-hover-color-custom);
  font-weight: var(--anw-link-hover-font-weight);
  text-decoration: var(--anw-link-hover-text-decoration);
}

a.anw-plain, a.anw-plain:hover {
  background: inherit;
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
}

a.anw-plain {
  border: 0 !important;
  text-shadow: none !important;
}

.anw-link-blue, .anw-link-text {
  border-bottom: var(--anw-link-text-border);
  text-decoration: none;
}

.anw-link-blue:hover, .anw-link-text:hover {
  background-color: transparent;
  border-bottom: var(--anw-link-text-hover-border);
  text-decoration: var(--anw-link-text-hover-text-decoration);
}

.h1 a, .h1 a:hover, .h2 a, .h2 a:hover, .h3 a, .h3 a:hover, .h4 a, .h4 a:hover, .h5 a, .h5 a:hover, h1 a, h1 a:hover, h2 a, h2 a:hover, h3 a, h3 a:hover, h4 a, h4 a:hover, h5 a, h5 a:hover {
  background-color: inherit;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

.h1 a:hover, .h2 a:hover, .h3 a:hover, .h4 a:hover, .h5 a:hover, h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover {
  border-bottom: 2px solid #c2c2c2;
}

.anw-link-blue:hover {
  text-decoration: none;
}

.anw-link-blue-900 {
  color: #1d4ed8;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
}

.anw-link-blue-900:hover {
  text-decoration: underline;
  border-bottom: none;
  color: #1d4ed8;
  font-weight: 400;
  background-color: transparent;
}

.anw-link-read-more {
  font-size: 1.1rem;
}

.anw-link-read-more .anw-page-link, .anw-link-read-more .anw-page-link:hover {
  background: 0 0;
  color: #333;
  font-weight: 600;
  line-height: 1.7rem;
  text-decoration: none;
}

.anw-link-read-more .anw-page-link {
  border-bottom: 2px solid #333;
}

.anw-link-read-more .anw-page-link:hover {
  border-color: #e95d0f;
  cursor: pointer;
}

.anw-link-read-more i {
  color: #e95d0f;
  margin-top: 0.35rem;
}

ul {
  padding-left: 19px;
}

ol {
  counter-reset: li;
}

ol li {
  counter-increment: li;
}

ol li::before {
  content: counter(li);
  color: #e95d0f;
  direction: rtl;
  left: 0;
  margin-left: -1em;
  position: absolute;
  text-align: right;
  width: 1.2em;
}

.list-unstyled ul {
  padding-left: 32px;
}

[class*=" anw-list-"] li::before, [class^=anw-list-] li::before {
  color: #e95d0f;
  display: inline-block;
  font-family: "Font Awesome 6 Pro";
  font-size: 1.375em;
  font-weight: 300;
  margin-left: -0.625em;
  position: relative;
  top: 0.1rem;
  width: 0.625em;
}

.anw-list-arrow {
  padding-left: 16px;
}

.anw-list-arrow li::before {
  content: "";
}

.anw-list-check li::before {
  content: "";
  margin-left: -1.1em;
  width: 1.1em;
}

.anw-list-text-heading {
  font-size: 20px;
  font-weight: 600;
}

.anw-bullet-list li i {
  margin-top: 0.2rem;
}

.anw-bullet-list li a {
  border: 1px solid transparent;
}

.anw-bullet-list li:first-child {
  margin-left: 0;
}

.anw-bullet-inline-list li {
  display: inline;
}

.anw-bullet-inline-list li:not(:last-child):after {
  content: " • ";
}

b, strong {
  font-weight: 600;
}

.anw-font-xs {
  font-size: 0.75rem;
}

.anw-font-sm {
  font-size: 0.875rem;
}

.anw-font-md {
  font-size: 1rem;
}

.anw-text-gray-400 {
  color: #c2c2c2;
}

.anw-text-gray-450 {
  color: #adadad;
}

.anw-fa-xxs {
  font-size: 0.25em;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s;
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing, .collapsing.collapse-horizontal {
    transition: none;
  }
}
/*!
 * Bootstrap Grid v5.2.0 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.container, .container-fluid, .container-xs, .row > * {
  width: 100%;
  padding-right: calc(var(--anw-gutter-x) * .5);
  padding-left: calc(var(--anw-gutter-x) * .5);
}

:root {
  --anw-blue:#385273;
  --anw-indigo:#6610f2;
  --anw-purple:#6f42c1;
  --anw-pink:#d63384;
  --anw-red:#990000;
  --anw-orange:#e95d0f;
  --anw-yellow:#ff9600;
  --anw-green:#0cb949;
  --anw-teal:#0cb949;
  --anw-cyan:#01cbd9;
  --anw-black:#000;
  --anw-white:#fff;
  --anw-gray:#666666;
  --anw-gray-dark:#5c5c5c;
  --anw-gray-150:#f6f6f6;
  --anw-gray-350:#ebebeb;
  --anw-gray-400:#c2c2c2;
  --anw-gray-450:#adadad;
  --anw-gray-550:#858585;
  --anw-primary:#e95d0f;
  --anw-secondary:#0cb949;
  --anw-success:#38734b;
  --anw-info:#385273;
  --anw-warning:#ff9600;
  --anw-danger:#990000;
  --anw-light:#f0f0f0;
  --anw-dark:#333;
  --anw-primary-rgb:233,93,15;
  --anw-secondary-rgb:12,185,73;
  --anw-success-rgb:56,115,75;
  --anw-info-rgb:56,82,115;
  --anw-warning-rgb:255,150,0;
  --anw-danger-rgb:153,0,0;
  --anw-light-rgb:240,240,240;
  --anw-dark-rgb:51,51,51;
  --anw-white-rgb:255,255,255;
  --anw-black-rgb:0,0,0;
  --anw-body-color-rgb:51,51,51;
  --anw-body-bg-rgb:255,255,255;
  --anw-font-sans-serif:"Open Sans","Segoe UI",Tahoma,sans-serif;
  --anw-font-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --anw-gradient:linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --anw-body-font-family:var(--anw-font-sans-serif);
  --anw-body-font-size:1rem;
  --anw-body-font-weight:400;
  --anw-body-line-height:1.375;
  --anw-body-color:#333;
  --anw-body-bg:#fff;
  --anw-border-width:1px;
  --anw-border-style:solid;
  --anw-border-color:#c2c2c2;
  --anw-border-color-translucent:rgba(0, 0, 0, 0.175);
  --anw-border-radius:4px;
  --anw-border-radius-sm:2px;
  --anw-border-radius-lg:10px;
  --anw-border-radius-xl:1rem;
  --anw-border-radius-2xl:2rem;
  --anw-border-radius-pill:50rem;
  --anw-link-color:#e95d0f;
  --anw-link-hover-color:inherit;
  --anw-code-color:#d63384;
  --anw-highlight-bg:#ffeacc;
}

.container, .container-fluid, .container-xs {
  --anw-gutter-x:20px;
  --anw-gutter-y:0;
  margin-right: auto;
  margin-left: auto;
}

.container {
  max-width: 1200px;
}

.row {
  --anw-gutter-x:20px;
  --anw-gutter-y:0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--anw-gutter-y));
  margin-right: calc(-.5 * var(--anw-gutter-x));
  margin-left: calc(-.5 * var(--anw-gutter-x));
}

.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  max-width: 100%;
  margin-top: var(--anw-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2, .col-3 {
  flex: 0 0 auto;
}

.col-2 {
  width: 16.66666667%;
}

.col-3 {
  width: 25%;
}

.col-4, .col-5 {
  flex: 0 0 auto;
}

.col-4 {
  width: 33.33333333%;
}

.col-5 {
  width: 41.66666667%;
}

.col-6, .col-7 {
  flex: 0 0 auto;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33333333%;
}

.col-8, .col-9 {
  flex: 0 0 auto;
}

.col-8 {
  width: 66.66666667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0, .gx-0 {
  --anw-gutter-x:0;
}

.g-0, .gy-0 {
  --anw-gutter-y:0;
}

.g-1, .gx-1 {
  --anw-gutter-x:0.25rem;
}

.g-1, .gy-1 {
  --anw-gutter-y:0.25rem;
}

.g-2, .gx-2 {
  --anw-gutter-x:0.5rem;
}

.g-2, .gy-2 {
  --anw-gutter-y:0.5rem;
}

.g-3, .gx-3 {
  --anw-gutter-x:1rem;
}

.g-3, .gy-3 {
  --anw-gutter-y:1rem;
}

.g-4, .gx-4 {
  --anw-gutter-x:1.5rem;
}

.g-4, .gy-4 {
  --anw-gutter-y:1.5rem;
}

.g-5, .gx-5 {
  --anw-gutter-x:2rem;
}

.g-5, .gy-5 {
  --anw-gutter-y:2rem;
}

.g-6, .gx-6 {
  --anw-gutter-x:2.5rem;
}

.g-6, .gy-6 {
  --anw-gutter-y:2.5rem;
}

.g-7, .gx-7 {
  --anw-gutter-x:3rem;
}

.g-7, .gy-7 {
  --anw-gutter-y:3rem;
}

.g-8, .gx-8 {
  --anw-gutter-x:3.5rem;
}

.g-8, .gy-8 {
  --anw-gutter-y:3.5rem;
}

.g-9, .gx-9 {
  --anw-gutter-x:4rem;
}

.g-9, .gy-9 {
  --anw-gutter-y:4rem;
}

.g-10, .gx-10 {
  --anw-gutter-x:4.5rem;
}

.g-10, .gy-10 {
  --anw-gutter-y:4.5rem;
}

@media (min-width: 468px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0, .gx-sm-0 {
    --anw-gutter-x:0;
  }

  .g-sm-0, .gy-sm-0 {
    --anw-gutter-y:0;
  }

  .g-sm-1, .gx-sm-1 {
    --anw-gutter-x:0.25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --anw-gutter-y:0.25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --anw-gutter-x:0.5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --anw-gutter-y:0.5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --anw-gutter-x:1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --anw-gutter-y:1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --anw-gutter-x:1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --anw-gutter-y:1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --anw-gutter-x:2rem;
  }

  .g-sm-5, .gy-sm-5 {
    --anw-gutter-y:2rem;
  }

  .g-sm-6, .gx-sm-6 {
    --anw-gutter-x:2.5rem;
  }

  .g-sm-6, .gy-sm-6 {
    --anw-gutter-y:2.5rem;
  }

  .g-sm-7, .gx-sm-7 {
    --anw-gutter-x:3rem;
  }

  .g-sm-7, .gy-sm-7 {
    --anw-gutter-y:3rem;
  }

  .g-sm-8, .gx-sm-8 {
    --anw-gutter-x:3.5rem;
  }

  .g-sm-8, .gy-sm-8 {
    --anw-gutter-y:3.5rem;
  }

  .g-sm-9, .gx-sm-9 {
    --anw-gutter-x:4rem;
  }

  .g-sm-9, .gy-sm-9 {
    --anw-gutter-y:4rem;
  }

  .g-sm-10, .gx-sm-10 {
    --anw-gutter-x:4.5rem;
  }

  .g-sm-10, .gy-sm-10 {
    --anw-gutter-y:4.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0, .gx-md-0 {
    --anw-gutter-x:0;
  }

  .g-md-0, .gy-md-0 {
    --anw-gutter-y:0;
  }

  .g-md-1, .gx-md-1 {
    --anw-gutter-x:0.25rem;
  }

  .g-md-1, .gy-md-1 {
    --anw-gutter-y:0.25rem;
  }

  .g-md-2, .gx-md-2 {
    --anw-gutter-x:0.5rem;
  }

  .g-md-2, .gy-md-2 {
    --anw-gutter-y:0.5rem;
  }

  .g-md-3, .gx-md-3 {
    --anw-gutter-x:1rem;
  }

  .g-md-3, .gy-md-3 {
    --anw-gutter-y:1rem;
  }

  .g-md-4, .gx-md-4 {
    --anw-gutter-x:1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --anw-gutter-y:1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --anw-gutter-x:2rem;
  }

  .g-md-5, .gy-md-5 {
    --anw-gutter-y:2rem;
  }

  .g-md-6, .gx-md-6 {
    --anw-gutter-x:2.5rem;
  }

  .g-md-6, .gy-md-6 {
    --anw-gutter-y:2.5rem;
  }

  .g-md-7, .gx-md-7 {
    --anw-gutter-x:3rem;
  }

  .g-md-7, .gy-md-7 {
    --anw-gutter-y:3rem;
  }

  .g-md-8, .gx-md-8 {
    --anw-gutter-x:3.5rem;
  }

  .g-md-8, .gy-md-8 {
    --anw-gutter-y:3.5rem;
  }

  .g-md-9, .gx-md-9 {
    --anw-gutter-x:4rem;
  }

  .g-md-9, .gy-md-9 {
    --anw-gutter-y:4rem;
  }

  .g-md-10, .gx-md-10 {
    --anw-gutter-x:4.5rem;
  }

  .g-md-10, .gy-md-10 {
    --anw-gutter-y:4.5rem;
  }
}
@media (min-width: 1024px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0, .gx-lg-0 {
    --anw-gutter-x:0;
  }

  .g-lg-0, .gy-lg-0 {
    --anw-gutter-y:0;
  }

  .g-lg-1, .gx-lg-1 {
    --anw-gutter-x:0.25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --anw-gutter-y:0.25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --anw-gutter-x:0.5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --anw-gutter-y:0.5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --anw-gutter-x:1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --anw-gutter-y:1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --anw-gutter-x:1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --anw-gutter-y:1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --anw-gutter-x:2rem;
  }

  .g-lg-5, .gy-lg-5 {
    --anw-gutter-y:2rem;
  }

  .g-lg-6, .gx-lg-6 {
    --anw-gutter-x:2.5rem;
  }

  .g-lg-6, .gy-lg-6 {
    --anw-gutter-y:2.5rem;
  }

  .g-lg-7, .gx-lg-7 {
    --anw-gutter-x:3rem;
  }

  .g-lg-7, .gy-lg-7 {
    --anw-gutter-y:3rem;
  }

  .g-lg-8, .gx-lg-8 {
    --anw-gutter-x:3.5rem;
  }

  .g-lg-8, .gy-lg-8 {
    --anw-gutter-y:3.5rem;
  }

  .g-lg-9, .gx-lg-9 {
    --anw-gutter-x:4rem;
  }

  .g-lg-9, .gy-lg-9 {
    --anw-gutter-y:4rem;
  }

  .g-lg-10, .gx-lg-10 {
    --anw-gutter-x:4.5rem;
  }

  .g-lg-10, .gy-lg-10 {
    --anw-gutter-y:4.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0, .gx-xl-0 {
    --anw-gutter-x:0;
  }

  .g-xl-0, .gy-xl-0 {
    --anw-gutter-y:0;
  }

  .g-xl-1, .gx-xl-1 {
    --anw-gutter-x:0.25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --anw-gutter-y:0.25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --anw-gutter-x:0.5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --anw-gutter-y:0.5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --anw-gutter-x:1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --anw-gutter-y:1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --anw-gutter-x:1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --anw-gutter-y:1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --anw-gutter-x:2rem;
  }

  .g-xl-5, .gy-xl-5 {
    --anw-gutter-y:2rem;
  }

  .g-xl-6, .gx-xl-6 {
    --anw-gutter-x:2.5rem;
  }

  .g-xl-6, .gy-xl-6 {
    --anw-gutter-y:2.5rem;
  }

  .g-xl-7, .gx-xl-7 {
    --anw-gutter-x:3rem;
  }

  .g-xl-7, .gy-xl-7 {
    --anw-gutter-y:3rem;
  }

  .g-xl-8, .gx-xl-8 {
    --anw-gutter-x:3.5rem;
  }

  .g-xl-8, .gy-xl-8 {
    --anw-gutter-y:3.5rem;
  }

  .g-xl-9, .gx-xl-9 {
    --anw-gutter-x:4rem;
  }

  .g-xl-9, .gy-xl-9 {
    --anw-gutter-y:4rem;
  }

  .g-xl-10, .gx-xl-10 {
    --anw-gutter-x:4.5rem;
  }

  .g-xl-10, .gy-xl-10 {
    --anw-gutter-y:4.5rem;
  }
}
@media (min-width: 1600px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --anw-gutter-x:0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --anw-gutter-y:0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --anw-gutter-x:0.25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --anw-gutter-y:0.25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --anw-gutter-x:0.5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --anw-gutter-y:0.5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --anw-gutter-x:1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --anw-gutter-y:1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --anw-gutter-x:1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --anw-gutter-y:1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --anw-gutter-x:2rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --anw-gutter-y:2rem;
  }

  .g-xxl-6, .gx-xxl-6 {
    --anw-gutter-x:2.5rem;
  }

  .g-xxl-6, .gy-xxl-6 {
    --anw-gutter-y:2.5rem;
  }

  .g-xxl-7, .gx-xxl-7 {
    --anw-gutter-x:3rem;
  }

  .g-xxl-7, .gy-xxl-7 {
    --anw-gutter-y:3rem;
  }

  .g-xxl-8, .gx-xxl-8 {
    --anw-gutter-x:3.5rem;
  }

  .g-xxl-8, .gy-xxl-8 {
    --anw-gutter-y:3.5rem;
  }

  .g-xxl-9, .gx-xxl-9 {
    --anw-gutter-x:4rem;
  }

  .g-xxl-9, .gy-xxl-9 {
    --anw-gutter-y:4rem;
  }

  .g-xxl-10, .gx-xxl-10 {
    --anw-gutter-x:4.5rem;
  }

  .g-xxl-10, .gy-xxl-10 {
    --anw-gutter-y:4.5rem;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-8 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-10 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.mt-8 {
  margin-top: 3.5rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.me-8 {
  margin-right: 3.5rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.mb-8 {
  margin-bottom: 3.5rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.ms-8 {
  margin-left: 3.5rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 4.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n6 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n7 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n8 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n9 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n10 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n7 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n8 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n10 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n6 {
  margin-top: -2.5rem !important;
}

.mt-n7 {
  margin-top: -3rem !important;
}

.mt-n8 {
  margin-top: -3.5rem !important;
}

.mt-n9 {
  margin-top: -4rem !important;
}

.mt-n10 {
  margin-top: -4.5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n6 {
  margin-right: -2.5rem !important;
}

.me-n7 {
  margin-right: -3rem !important;
}

.me-n8 {
  margin-right: -3.5rem !important;
}

.me-n9 {
  margin-right: -4rem !important;
}

.me-n10 {
  margin-right: -4.5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n6 {
  margin-bottom: -2.5rem !important;
}

.mb-n7 {
  margin-bottom: -3rem !important;
}

.mb-n8 {
  margin-bottom: -3.5rem !important;
}

.mb-n9 {
  margin-bottom: -4rem !important;
}

.mb-n10 {
  margin-bottom: -4.5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n6 {
  margin-left: -2.5rem !important;
}

.ms-n7 {
  margin-left: -3rem !important;
}

.ms-n8 {
  margin-left: -3.5rem !important;
}

.ms-n9 {
  margin-left: -4rem !important;
}

.ms-n10 {
  margin-left: -4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-8 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-10 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pe-8 {
  padding-right: 3.5rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 3.5rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.ps-8 {
  padding-left: 3.5rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 4.5rem !important;
}

@media (min-width: 468px) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 2rem !important;
  }

  .m-sm-6 {
    margin: 2.5rem !important;
  }

  .m-sm-7 {
    margin: 3rem !important;
  }

  .m-sm-8 {
    margin: 3.5rem !important;
  }

  .m-sm-9 {
    margin: 4rem !important;
  }

  .m-sm-10 {
    margin: 4.5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 2rem !important;
  }

  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 3rem !important;
  }

  .mt-sm-8 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-9 {
    margin-top: 4rem !important;
  }

  .mt-sm-10 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 2rem !important;
  }

  .me-sm-6 {
    margin-right: 2.5rem !important;
  }

  .me-sm-7 {
    margin-right: 3rem !important;
  }

  .me-sm-8 {
    margin-right: 3.5rem !important;
  }

  .me-sm-9 {
    margin-right: 4rem !important;
  }

  .me-sm-10 {
    margin-right: 4.5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 2rem !important;
  }

  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 3rem !important;
  }

  .ms-sm-8 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-9 {
    margin-left: 4rem !important;
  }

  .ms-sm-10 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -2rem !important;
  }

  .m-sm-n6 {
    margin: -2.5rem !important;
  }

  .m-sm-n7 {
    margin: -3rem !important;
  }

  .m-sm-n8 {
    margin: -3.5rem !important;
  }

  .m-sm-n9 {
    margin: -4rem !important;
  }

  .m-sm-n10 {
    margin: -4.5rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-sm-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -2rem !important;
  }

  .mt-sm-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -3rem !important;
  }

  .mt-sm-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n9 {
    margin-top: -4rem !important;
  }

  .mt-sm-n10 {
    margin-top: -4.5rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -2rem !important;
  }

  .me-sm-n6 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -3rem !important;
  }

  .me-sm-n8 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n9 {
    margin-right: -4rem !important;
  }

  .me-sm-n10 {
    margin-right: -4.5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -4.5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -2rem !important;
  }

  .ms-sm-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -3rem !important;
  }

  .ms-sm-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n9 {
    margin-left: -4rem !important;
  }

  .ms-sm-n10 {
    margin-left: -4.5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 2rem !important;
  }

  .p-sm-6 {
    padding: 2.5rem !important;
  }

  .p-sm-7 {
    padding: 3rem !important;
  }

  .p-sm-8 {
    padding: 3.5rem !important;
  }

  .p-sm-9 {
    padding: 4rem !important;
  }

  .p-sm-10 {
    padding: 4.5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 2rem !important;
  }

  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 3rem !important;
  }

  .pt-sm-8 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-9 {
    padding-top: 4rem !important;
  }

  .pt-sm-10 {
    padding-top: 4.5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 2rem !important;
  }

  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 3rem !important;
  }

  .pe-sm-8 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-9 {
    padding-right: 4rem !important;
  }

  .pe-sm-10 {
    padding-right: 4.5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 2rem !important;
  }

  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 3rem !important;
  }

  .ps-sm-8 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-9 {
    padding-left: 4rem !important;
  }

  .ps-sm-10 {
    padding-left: 4.5rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 2rem !important;
  }

  .m-md-6 {
    margin: 2.5rem !important;
  }

  .m-md-7 {
    margin: 3rem !important;
  }

  .m-md-8 {
    margin: 3.5rem !important;
  }

  .m-md-9 {
    margin: 4rem !important;
  }

  .m-md-10 {
    margin: 4.5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 2rem !important;
  }

  .mt-md-6 {
    margin-top: 2.5rem !important;
  }

  .mt-md-7 {
    margin-top: 3rem !important;
  }

  .mt-md-8 {
    margin-top: 3.5rem !important;
  }

  .mt-md-9 {
    margin-top: 4rem !important;
  }

  .mt-md-10 {
    margin-top: 4.5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 2rem !important;
  }

  .me-md-6 {
    margin-right: 2.5rem !important;
  }

  .me-md-7 {
    margin-right: 3rem !important;
  }

  .me-md-8 {
    margin-right: 3.5rem !important;
  }

  .me-md-9 {
    margin-right: 4rem !important;
  }

  .me-md-10 {
    margin-right: 4.5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2rem !important;
  }

  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 3rem !important;
  }

  .mb-md-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 4rem !important;
  }

  .mb-md-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 2rem !important;
  }

  .ms-md-6 {
    margin-left: 2.5rem !important;
  }

  .ms-md-7 {
    margin-left: 3rem !important;
  }

  .ms-md-8 {
    margin-left: 3.5rem !important;
  }

  .ms-md-9 {
    margin-left: 4rem !important;
  }

  .ms-md-10 {
    margin-left: 4.5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -2rem !important;
  }

  .m-md-n6 {
    margin: -2.5rem !important;
  }

  .m-md-n7 {
    margin: -3rem !important;
  }

  .m-md-n8 {
    margin: -3.5rem !important;
  }

  .m-md-n9 {
    margin: -4rem !important;
  }

  .m-md-n10 {
    margin: -4.5rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-md-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -2rem !important;
  }

  .mt-md-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -3rem !important;
  }

  .mt-md-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n9 {
    margin-top: -4rem !important;
  }

  .mt-md-n10 {
    margin-top: -4.5rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -2rem !important;
  }

  .me-md-n6 {
    margin-right: -2.5rem !important;
  }

  .me-md-n7 {
    margin-right: -3rem !important;
  }

  .me-md-n8 {
    margin-right: -3.5rem !important;
  }

  .me-md-n9 {
    margin-right: -4rem !important;
  }

  .me-md-n10 {
    margin-right: -4.5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -4.5rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -2rem !important;
  }

  .ms-md-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -3rem !important;
  }

  .ms-md-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n9 {
    margin-left: -4rem !important;
  }

  .ms-md-n10 {
    margin-left: -4.5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 2rem !important;
  }

  .p-md-6 {
    padding: 2.5rem !important;
  }

  .p-md-7 {
    padding: 3rem !important;
  }

  .p-md-8 {
    padding: 3.5rem !important;
  }

  .p-md-9 {
    padding: 4rem !important;
  }

  .p-md-10 {
    padding: 4.5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 2rem !important;
  }

  .pt-md-6 {
    padding-top: 2.5rem !important;
  }

  .pt-md-7 {
    padding-top: 3rem !important;
  }

  .pt-md-8 {
    padding-top: 3.5rem !important;
  }

  .pt-md-9 {
    padding-top: 4rem !important;
  }

  .pt-md-10 {
    padding-top: 4.5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 2rem !important;
  }

  .pe-md-6 {
    padding-right: 2.5rem !important;
  }

  .pe-md-7 {
    padding-right: 3rem !important;
  }

  .pe-md-8 {
    padding-right: 3.5rem !important;
  }

  .pe-md-9 {
    padding-right: 4rem !important;
  }

  .pe-md-10 {
    padding-right: 4.5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2rem !important;
  }

  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 3rem !important;
  }

  .pb-md-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-9 {
    padding-bottom: 4rem !important;
  }

  .pb-md-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 2rem !important;
  }

  .ps-md-6 {
    padding-left: 2.5rem !important;
  }

  .ps-md-7 {
    padding-left: 3rem !important;
  }

  .ps-md-8 {
    padding-left: 3.5rem !important;
  }

  .ps-md-9 {
    padding-left: 4rem !important;
  }

  .ps-md-10 {
    padding-left: 4.5rem !important;
  }
}
@media (min-width: 1024px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 2rem !important;
  }

  .m-lg-6 {
    margin: 2.5rem !important;
  }

  .m-lg-7 {
    margin: 3rem !important;
  }

  .m-lg-8 {
    margin: 3.5rem !important;
  }

  .m-lg-9 {
    margin: 4rem !important;
  }

  .m-lg-10 {
    margin: 4.5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 2rem !important;
  }

  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 3rem !important;
  }

  .mt-lg-8 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-9 {
    margin-top: 4rem !important;
  }

  .mt-lg-10 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 2rem !important;
  }

  .me-lg-6 {
    margin-right: 2.5rem !important;
  }

  .me-lg-7 {
    margin-right: 3rem !important;
  }

  .me-lg-8 {
    margin-right: 3.5rem !important;
  }

  .me-lg-9 {
    margin-right: 4rem !important;
  }

  .me-lg-10 {
    margin-right: 4.5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 2rem !important;
  }

  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 3rem !important;
  }

  .ms-lg-8 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-9 {
    margin-left: 4rem !important;
  }

  .ms-lg-10 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -2rem !important;
  }

  .m-lg-n6 {
    margin: -2.5rem !important;
  }

  .m-lg-n7 {
    margin: -3rem !important;
  }

  .m-lg-n8 {
    margin: -3.5rem !important;
  }

  .m-lg-n9 {
    margin: -4rem !important;
  }

  .m-lg-n10 {
    margin: -4.5rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-lg-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -2rem !important;
  }

  .mt-lg-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -3rem !important;
  }

  .mt-lg-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n9 {
    margin-top: -4rem !important;
  }

  .mt-lg-n10 {
    margin-top: -4.5rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -2rem !important;
  }

  .me-lg-n6 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -3rem !important;
  }

  .me-lg-n8 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n9 {
    margin-right: -4rem !important;
  }

  .me-lg-n10 {
    margin-right: -4.5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -4.5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -2rem !important;
  }

  .ms-lg-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -3rem !important;
  }

  .ms-lg-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n9 {
    margin-left: -4rem !important;
  }

  .ms-lg-n10 {
    margin-left: -4.5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 2rem !important;
  }

  .p-lg-6 {
    padding: 2.5rem !important;
  }

  .p-lg-7 {
    padding: 3rem !important;
  }

  .p-lg-8 {
    padding: 3.5rem !important;
  }

  .p-lg-9 {
    padding: 4rem !important;
  }

  .p-lg-10 {
    padding: 4.5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 2rem !important;
  }

  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 3rem !important;
  }

  .pt-lg-8 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-9 {
    padding-top: 4rem !important;
  }

  .pt-lg-10 {
    padding-top: 4.5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 2rem !important;
  }

  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 3rem !important;
  }

  .pe-lg-8 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-9 {
    padding-right: 4rem !important;
  }

  .pe-lg-10 {
    padding-right: 4.5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 2rem !important;
  }

  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 3rem !important;
  }

  .ps-lg-8 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-9 {
    padding-left: 4rem !important;
  }

  .ps-lg-10 {
    padding-left: 4.5rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 2rem !important;
  }

  .m-xl-6 {
    margin: 2.5rem !important;
  }

  .m-xl-7 {
    margin: 3rem !important;
  }

  .m-xl-8 {
    margin: 3.5rem !important;
  }

  .m-xl-9 {
    margin: 4rem !important;
  }

  .m-xl-10 {
    margin: 4.5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 2rem !important;
  }

  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 3rem !important;
  }

  .mt-xl-8 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-9 {
    margin-top: 4rem !important;
  }

  .mt-xl-10 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 2rem !important;
  }

  .me-xl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xl-7 {
    margin-right: 3rem !important;
  }

  .me-xl-8 {
    margin-right: 3.5rem !important;
  }

  .me-xl-9 {
    margin-right: 4rem !important;
  }

  .me-xl-10 {
    margin-right: 4.5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 2rem !important;
  }

  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 3rem !important;
  }

  .ms-xl-8 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-9 {
    margin-left: 4rem !important;
  }

  .ms-xl-10 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -2rem !important;
  }

  .m-xl-n6 {
    margin: -2.5rem !important;
  }

  .m-xl-n7 {
    margin: -3rem !important;
  }

  .m-xl-n8 {
    margin: -3.5rem !important;
  }

  .m-xl-n9 {
    margin: -4rem !important;
  }

  .m-xl-n10 {
    margin: -4.5rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xl-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -3rem !important;
  }

  .mt-xl-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n9 {
    margin-top: -4rem !important;
  }

  .mt-xl-n10 {
    margin-top: -4.5rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -2rem !important;
  }

  .me-xl-n6 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -3rem !important;
  }

  .me-xl-n8 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n9 {
    margin-right: -4rem !important;
  }

  .me-xl-n10 {
    margin-right: -4.5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -4.5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xl-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -3rem !important;
  }

  .ms-xl-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n9 {
    margin-left: -4rem !important;
  }

  .ms-xl-n10 {
    margin-left: -4.5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 2rem !important;
  }

  .p-xl-6 {
    padding: 2.5rem !important;
  }

  .p-xl-7 {
    padding: 3rem !important;
  }

  .p-xl-8 {
    padding: 3.5rem !important;
  }

  .p-xl-9 {
    padding: 4rem !important;
  }

  .p-xl-10 {
    padding: 4.5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 2rem !important;
  }

  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 3rem !important;
  }

  .pt-xl-8 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-9 {
    padding-top: 4rem !important;
  }

  .pt-xl-10 {
    padding-top: 4.5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 2rem !important;
  }

  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 3rem !important;
  }

  .pe-xl-8 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-9 {
    padding-right: 4rem !important;
  }

  .pe-xl-10 {
    padding-right: 4.5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 2rem !important;
  }

  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 3rem !important;
  }

  .ps-xl-8 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-9 {
    padding-left: 4rem !important;
  }

  .ps-xl-10 {
    padding-left: 4.5rem !important;
  }
}
@media (min-width: 1600px) {
  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 2rem !important;
  }

  .m-xxl-6 {
    margin: 2.5rem !important;
  }

  .m-xxl-7 {
    margin: 3rem !important;
  }

  .m-xxl-8 {
    margin: 3.5rem !important;
  }

  .m-xxl-9 {
    margin: 4rem !important;
  }

  .m-xxl-10 {
    margin: 4.5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 2rem !important;
  }

  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 3rem !important;
  }

  .mt-xxl-8 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-9 {
    margin-top: 4rem !important;
  }

  .mt-xxl-10 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 2rem !important;
  }

  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 3rem !important;
  }

  .me-xxl-8 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-9 {
    margin-right: 4rem !important;
  }

  .me-xxl-10 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 2rem !important;
  }

  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 3rem !important;
  }

  .ms-xxl-8 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-9 {
    margin-left: 4rem !important;
  }

  .ms-xxl-10 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -2rem !important;
  }

  .m-xxl-n6 {
    margin: -2.5rem !important;
  }

  .m-xxl-n7 {
    margin: -3rem !important;
  }

  .m-xxl-n8 {
    margin: -3.5rem !important;
  }

  .m-xxl-n9 {
    margin: -4rem !important;
  }

  .m-xxl-n10 {
    margin: -4.5rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -4.5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -2rem !important;
  }

  .me-xxl-n6 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -3rem !important;
  }

  .me-xxl-n8 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n9 {
    margin-right: -4rem !important;
  }

  .me-xxl-n10 {
    margin-right: -4.5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -4.5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -4.5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 2rem !important;
  }

  .p-xxl-6 {
    padding: 2.5rem !important;
  }

  .p-xxl-7 {
    padding: 3rem !important;
  }

  .p-xxl-8 {
    padding: 3.5rem !important;
  }

  .p-xxl-9 {
    padding: 4rem !important;
  }

  .p-xxl-10 {
    padding: 4.5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 2rem !important;
  }

  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 3rem !important;
  }

  .pt-xxl-8 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-9 {
    padding-top: 4rem !important;
  }

  .pt-xxl-10 {
    padding-top: 4.5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 2rem !important;
  }

  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 3rem !important;
  }

  .pe-xxl-8 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-9 {
    padding-right: 4rem !important;
  }

  .pe-xxl-10 {
    padding-right: 4.5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 2rem !important;
  }

  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 3rem !important;
  }

  .ps-xxl-8 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-9 {
    padding-left: 4rem !important;
  }

  .ps-xxl-10 {
    padding-left: 4.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.btn-check:focus + .btn, .btn:focus, .btn:hover {
  color: var(--anw-btn-hover-color);
  background-color: var(--anw-btn-hover-bg);
  border-color: var(--anw-btn-hover-border-color);
}

.btn, .btn:hover, a.btn, button.btn {
  text-decoration: none;
}

.btn {
  --anw-btn-padding-x:var(--anw-btn-padding-x-custom);
  --anw-btn-padding-y:var(--anw-btn-padding-y-custom);
  --anw-btn-font-size:1rem;
  --anw-btn-font-weight:400;
  --anw-btn-line-height:1.375;
  --anw-btn-color:#333;
  --anw-btn-bg:transparent;
  --anw-btn-border-width:1px;
  --anw-btn-border-color:transparent;
  --anw-btn-border-radius:var(--anw-btn-border-radius-custom);
  --anw-btn-box-shadow:0 0 10px 0 rgba(0, 0, 0, 0.4);
  --anw-btn-disabled-opacity:1;
  --anw-btn-focus-box-shadow:0 0 0 0.25rem rgba(var(--anw-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--anw-btn-padding-y) var(--anw-btn-padding-x);
  font-family: var(--anw-btn-font-family);
  font-size: var(--anw-btn-font-size);
  font-weight: var(--anw-btn-font-weight);
  line-height: var(--anw-btn-line-height);
  color: var(--anw-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--anw-btn-border-width) solid var(--anw-btn-border-color);
  border-radius: var(--anw-btn-border-radius);
  background-color: var(--anw-btn-bg);
  box-shadow: var(--anw-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: normal;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn-link, .btn-link:focus, .btn-link:hover {
  text-decoration: underline;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: var(--anw-btn-box-shadow), var(--anw-btn-focus-box-shadow);
}

.btn-check:active + .btn, .btn-check:checked + .btn, .btn.active, .btn.show, .btn:active {
  color: var(--anw-btn-active-color);
  background-color: var(--anw-btn-active-bg);
  border-color: var(--anw-btn-active-border-color);
  box-shadow: var(--anw-btn-active-shadow);
}

.btn-outline-danger, .btn-outline-dark, .btn-outline-info, .btn-outline-primary, .btn-outline-secondary {
  font-weight: 400;
  background-color: #fff;
}

.btn-check:active + .btn:focus, .btn-check:checked + .btn:focus, .btn.active:focus, .btn.show:focus, .btn:active:focus {
  box-shadow: var(--anw-btn-active-shadow), var(--anw-btn-focus-box-shadow);
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  color: var(--anw-btn-disabled-color);
  pointer-events: none;
  background-color: var(--anw-btn-disabled-bg);
  border-color: var(--anw-btn-disabled-border-color);
  opacity: var(--anw-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --anw-btn-color:#000;
  --anw-btn-bg:#e95d0f;
  --anw-btn-border-color:#e95d0f;
  --anw-btn-hover-color:#000;
  --anw-btn-hover-bg:#ec7533;
  --anw-btn-hover-border-color:#eb6d27;
  --anw-btn-focus-shadow-rgb:198,79,13;
  --anw-btn-active-color:#000;
  --anw-btn-active-bg:#ed7d3f;
  --anw-btn-active-border-color:#eb6d27;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#000;
  --anw-btn-disabled-bg:#e95d0f;
  --anw-btn-disabled-border-color:#e95d0f;
  font-weight: 400;
}

.btn-secondary {
  --anw-btn-color:#000;
  --anw-btn-bg:#0cb949;
  --anw-btn-border-color:#0cb949;
  --anw-btn-hover-color:#000;
  --anw-btn-hover-bg:#30c464;
  --anw-btn-hover-border-color:#24c05b;
  --anw-btn-focus-shadow-rgb:10,157,62;
  --anw-btn-active-color:#000;
  --anw-btn-active-bg:#3dc76d;
  --anw-btn-active-border-color:#24c05b;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#000;
  --anw-btn-disabled-bg:#0cb949;
  --anw-btn-disabled-border-color:#0cb949;
  color: #fff !important;
  text-shadow: 1px 1px 1px #999;
}

.btn-info {
  --anw-btn-color:#fff;
  --anw-btn-bg:#385273;
  --anw-btn-border-color:#385273;
  --anw-btn-hover-color:#fff;
  --anw-btn-hover-bg:#304662;
  --anw-btn-hover-border-color:#2d425c;
  --anw-btn-focus-shadow-rgb:86,108,136;
  --anw-btn-active-color:#fff;
  --anw-btn-active-bg:#2d425c;
  --anw-btn-active-border-color:#2a3e56;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#fff;
  --anw-btn-disabled-bg:#385273;
  --anw-btn-disabled-border-color:#385273;
}

.btn-danger {
  --anw-btn-color:#fff;
  --anw-btn-bg:#990000;
  --anw-btn-border-color:#990000;
  --anw-btn-hover-color:#fff;
  --anw-btn-hover-bg:#820000;
  --anw-btn-hover-border-color:#7a0000;
  --anw-btn-focus-shadow-rgb:168,38,38;
  --anw-btn-active-color:#fff;
  --anw-btn-active-bg:#7a0000;
  --anw-btn-active-border-color:#730000;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#fff;
  --anw-btn-disabled-bg:#990000;
  --anw-btn-disabled-border-color:#990000;
}

.btn-dark {
  --anw-btn-color:#fff;
  --anw-btn-bg:#333;
  --anw-btn-border-color:#333;
  --anw-btn-hover-color:#fff;
  --anw-btn-hover-bg:#525252;
  --anw-btn-hover-border-color:#474747;
  --anw-btn-focus-shadow-rgb:82,82,82;
  --anw-btn-active-color:#fff;
  --anw-btn-active-bg:#5c5c5c;
  --anw-btn-active-border-color:#474747;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#fff;
  --anw-btn-disabled-bg:#333;
  --anw-btn-disabled-border-color:#333;
}

.btn-outline-primary {
  --anw-btn-color:#e95d0f;
  --anw-btn-border-color:#e95d0f;
  --anw-btn-hover-color:#000;
  --anw-btn-hover-bg:#e95d0f;
  --anw-btn-hover-border-color:#e95d0f;
  --anw-btn-focus-shadow-rgb:233,93,15;
  --anw-btn-active-color:#000;
  --anw-btn-active-bg:#e95d0f;
  --anw-btn-active-border-color:#e95d0f;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#e95d0f;
  --anw-btn-disabled-bg:transparent;
  --anw-btn-disabled-border-color:#e95d0f;
  --anw-gradient:none;
  --anw-btn-color:#e95d0f;
  --anw-btn-border-color:#e95d0f;
  --anw-btn-hover-color:#e95d0f;
  --anw-btn-hover-bg:#f9f9f9;
  --anw-btn-hover-border-color:#e95d0f;
  --anw-btn-focus-shadow-rgb:233,93,15;
  --anw-btn-active-color:#000;
  --anw-btn-active-bg:#f9f9f9;
  --anw-btn-active-border-color:#e95d0f;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#e95d0f;
  --anw-btn-disabled-bg:transparent;
  --anw-btn-disabled-border-color:#e95d0f;
  --anw-gradient:none;
}

.btn-outline-secondary {
  --anw-btn-color:#0cb949;
  --anw-btn-border-color:#0cb949;
  --anw-btn-hover-color:#000;
  --anw-btn-hover-bg:#0cb949;
  --anw-btn-hover-border-color:#0cb949;
  --anw-btn-focus-shadow-rgb:12,185,73;
  --anw-btn-active-color:#000;
  --anw-btn-active-bg:#0cb949;
  --anw-btn-active-border-color:#0cb949;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#0cb949;
  --anw-btn-disabled-bg:transparent;
  --anw-btn-disabled-border-color:#0cb949;
  --anw-gradient:none;
  --anw-btn-color:#0cb949;
  --anw-btn-border-color:#0cb949;
  --anw-btn-hover-color:#0cb949;
  --anw-btn-hover-bg:#f9f9f9;
  --anw-btn-hover-border-color:#0cb949;
  --anw-btn-focus-shadow-rgb:12,185,73;
  --anw-btn-active-color:#000;
  --anw-btn-active-bg:#f9f9f9;
  --anw-btn-active-border-color:#0cb949;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#0cb949;
  --anw-btn-disabled-bg:transparent;
  --anw-btn-disabled-border-color:#0cb949;
  --anw-gradient:none;
}

.btn-outline-info {
  --anw-btn-color:#385273;
  --anw-btn-border-color:#385273;
  --anw-btn-hover-color:#fff;
  --anw-btn-hover-bg:#385273;
  --anw-btn-hover-border-color:#385273;
  --anw-btn-focus-shadow-rgb:56,82,115;
  --anw-btn-active-color:#fff;
  --anw-btn-active-bg:#385273;
  --anw-btn-active-border-color:#385273;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#385273;
  --anw-btn-disabled-bg:transparent;
  --anw-btn-disabled-border-color:#385273;
  --anw-gradient:none;
  --anw-btn-color:#385273;
  --anw-btn-border-color:#385273;
  --anw-btn-hover-color:#385273;
  --anw-btn-hover-bg:#f9f9f9;
  --anw-btn-hover-border-color:#385273;
  --anw-btn-focus-shadow-rgb:56,82,115;
  --anw-btn-active-color:#000;
  --anw-btn-active-bg:#f9f9f9;
  --anw-btn-active-border-color:#385273;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#385273;
  --anw-btn-disabled-bg:transparent;
  --anw-btn-disabled-border-color:#385273;
  --anw-gradient:none;
}

.btn-outline-danger {
  --anw-btn-color:#990000;
  --anw-btn-border-color:#990000;
  --anw-btn-hover-color:#fff;
  --anw-btn-hover-bg:#990000;
  --anw-btn-hover-border-color:#990000;
  --anw-btn-focus-shadow-rgb:153,0,0;
  --anw-btn-active-color:#fff;
  --anw-btn-active-bg:#990000;
  --anw-btn-active-border-color:#990000;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#990000;
  --anw-btn-disabled-bg:transparent;
  --anw-btn-disabled-border-color:#990000;
  --anw-gradient:none;
  --anw-btn-color:#990000;
  --anw-btn-border-color:#990000;
  --anw-btn-hover-color:#990000;
  --anw-btn-hover-bg:#f9f9f9;
  --anw-btn-hover-border-color:#990000;
  --anw-btn-focus-shadow-rgb:153,0,0;
  --anw-btn-active-color:#000;
  --anw-btn-active-bg:#f9f9f9;
  --anw-btn-active-border-color:#990000;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#990000;
  --anw-btn-disabled-bg:transparent;
  --anw-btn-disabled-border-color:#990000;
  --anw-gradient:none;
}

.btn-outline-dark {
  --anw-btn-color:#333;
  --anw-btn-border-color:#333;
  --anw-btn-hover-color:#fff;
  --anw-btn-hover-bg:#333;
  --anw-btn-hover-border-color:#333;
  --anw-btn-focus-shadow-rgb:51,51,51;
  --anw-btn-active-color:#fff;
  --anw-btn-active-bg:#333;
  --anw-btn-active-border-color:#333;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#333;
  --anw-btn-disabled-bg:transparent;
  --anw-btn-disabled-border-color:#333;
  --anw-gradient:none;
  --anw-btn-color:#333;
  --anw-btn-border-color:#333;
  --anw-btn-hover-color:#333;
  --anw-btn-hover-bg:#f9f9f9;
  --anw-btn-hover-border-color:#333;
  --anw-btn-focus-shadow-rgb:51,51,51;
  --anw-btn-active-color:#000;
  --anw-btn-active-bg:#f9f9f9;
  --anw-btn-active-border-color:#333;
  --anw-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --anw-btn-disabled-color:#333;
  --anw-btn-disabled-bg:transparent;
  --anw-btn-disabled-border-color:#333;
  --anw-gradient:none;
}

.btn-link {
  --anw-btn-font-weight:400;
  --anw-btn-color:var(--anw-link-color);
  --anw-btn-bg:transparent;
  --anw-btn-border-color:transparent;
  --anw-btn-hover-color:var(--anw-link-hover-color);
  --anw-btn-hover-border-color:transparent;
  --anw-btn-active-color:var(--anw-link-hover-color);
  --anw-btn-active-border-color:transparent;
  --anw-btn-disabled-color:#a3a3a3;
  --anw-btn-disabled-border-color:transparent;
  --anw-btn-box-shadow:none;
  --anw-btn-focus-shadow-rgb:198,79,13;
  --anw-link-color:#e95d0f;
}

.btn-link:focus {
  color: var(--anw-btn-color);
}

.btn-link:hover {
  color: var(--anw-btn-hover-color);
}

.btn-danger:hover, .btn-dark:hover, .btn-info:hover, .btn-primary:hover, .btn-secondary:hover {
  color: #fff;
  font-weight: 400;
  box-shadow: none;
}

.btn-lg {
  --anw-btn-padding-y:var(--anw-btn-padding-y-custom);
  --anw-btn-padding-x:2rem;
  --anw-btn-font-size:calc(1.2875rem + 0.45vw);
  --anw-btn-border-radius:var(--anw-btn-border-radius-custom);
}

@media (min-width: 1200px) {
  .btn-lg {
    --anw-btn-font-size:1.625rem;
  }
}
.btn-sm {
  --anw-btn-padding-y:0.313rem;
  --anw-btn-padding-x:0.625rem;
  --anw-btn-font-size:0.875rem;
  --anw-btn-border-radius:var(--anw-btn-border-radius-custom);
  line-height: 1.5;
}

.btn-outline-primary:hover, .btn-secondary {
  font-weight: 400;
}

.btn-info, .btn-outline-secondary:hover {
  font-weight: 400;
}

.btn-danger, .btn-outline-info:hover {
  font-weight: 400;
}

.btn-dark, .btn-outline-danger:hover {
  font-weight: 400;
}

.btn-outline-dark:hover {
  font-weight: 400;
}

.btn-check:focus + .btn {
  box-shadow: var(--anw-btn-box-shadow), var(--anw-btn-focus-box-shadow);
}

.btn-dark, .btn-info, .btn-primary {
  --anw-btn-color:#fff;
  --anw-btn-hover-color:#fff;
}

a.btn.disabled, a.btn:disabled, button.btn.disabled, button.btn:disabled {
  pointer-events: all;
}

.btn.btn-primary:not(.btn-primary):not(.btn-secondary):not(.btn-info):not(.btn-dark), .btn:not(:focus):not(.btn-primary):not(.btn-secondary):not(.btn-info):not(.btn-dark) {
  --anw-btn-box-shadow:0 0;
}

.btn.disabled, .btn:disabled {
  border-color: #a3a3a3;
  cursor: not-allowed;
}

.btn.disabled[class*=" btn-outline-"], .btn.disabled[class^=btn-outline-], .btn:disabled[class*=" btn-outline-"], .btn:disabled[class^=btn-outline-] {
  background-color: #fff;
  color: #a3a3a3;
}

.btn.disabled:not([class^=btn-outline-]):not([class*=" btn-outline-"]), .btn:disabled:not([class^=btn-outline-]):not([class*=" btn-outline-"]) {
  background-color: #a3a3a3;
}

.btn:focus {
  --anw-btn-box-shadow:0 0;
}

.btn i {
  margin-right: 0.6em;
}

.anw-customizable-registration-button {
  border-color: #fff;
  border-radius: 10px;
  color: #fff !important;
  font-size: 1.125rem;
}

@media (min-width: 468px) {
  .anw-customizable-registration-button {
    font-size: 1.5625rem;
  }
}
.anw-customizable-small-button {
  background: #858585;
  border-color: transparent;
  border-radius: 2px;
  box-shadow: none;
  color: #fff;
  display: inline-flex;
  font-size: 0.7rem;
  padding: 2px 7px;
  text-transform: uppercase;
  position: relative;
}

.anw-customizable-small-button:hover {
  background: #e95d0f;
  border-color: #e95d0f;
  color: #fff;
}

.img-fluid, .img-thumbnail {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--anw-border-color);
  border-radius: 4px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 87.5%;
  color: #666;
}

.anw-cursor-pointer {
  cursor: pointer;
}

.anw-cursor-text {
  cursor: text;
}

.anw-cursor-not-allowed {
  cursor: not-allowed;
}

.anw-hyphens {
  hyphens: auto;
  word-break: break-word !important;
  word-wrap: break-word !important;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
}

:focus-visible {
  outline-color: #e95d0f;
  text-decoration: none;
}

.anw-focus-within:focus-within {
  position: relative;
  z-index: 10;
}

.anw-stretched-link-wrapper:hover .anw-pseudo-link {
  text-decoration: underline;
}