@import '../../../defaults';

.formFieldStatusContainer {
  margin: 5px;
  height: 25px;
  margin: 10px;

  @include media-breakpoint-up(lg) {
    margin: 5px;
  }

  &:before {
    position: absolute;
    margin-top: 2px;
    margin-left: -30px;
    font-family: $anw-fontawesome-font-family;
    font-size: 18px;
    content: '';
  }
}

.input-success ~ .formFieldStatusContainer:before {
  color: $success;
  content: '\f00c';
}

.input-error,
.form-control.input-error {
  padding-right: map-get($spacers, 6);

  & ~ .formFieldStatusContainer {
    &::before {
      color: $red;
      content: '\f06a';
    }
  }
}

// https://jira.anwalt.de/browse/PRO-7176
_:-ms-fullscreen,
:root .input-error {
  padding-right: 0;
}

.input-validating ~ .formFieldStatusContainer:before {
  // same animation as for the class "fa-spin" in node_modules/@fortawesome/fontawesome-pro/scss/_animated.scss
  animation: fa-spin 2s infinite linear;
  content: '\f110';
}
