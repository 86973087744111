.input-error,
.input-error > input {
  background-color: $gray-150;
}

.input-error,
.input-success {
  ~ ul li {
    float: left;
    &:before {
      position: static;
      vertical-align: baseline;
      display: none;
      margin-right: 5px;
      font-weight: $font-weight-light;
      font-family: $anw-fontawesome-font-family;
      font-size: 16px;
      content: '\f071';
    }
  }
}

.input-error ~ ul li:before {
  content: '\f071';
}

.input-success ~ ul li:before {
  content: '\f00c';
}

.form .anw-hide {
  @include hide;
}

.form .hidden {
  display: none;
}

.form input.disabled,
.form select.disabled {
  color: $gray-550;
  background-color: $gray-300;
  border: $gray-400;
  cursor: default;
}

.form label {
  cursor: pointer;
  display: inline-block;
}

.form label .meta {
  display: block;
  font-size: 11px;
  line-height: 16px;
  font-weight: normal;
}

.actionText {
  box-sizing: border-box;
  float: left;
  height: 36px;
  padding: 2px 5px 2px 10px;
  border: 1px solid $gray-500;
  border-radius: 0;
  font-size: 1em;
  -webkit-appearance: none;
}

.form {
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='range'],
  textarea {
    width: 100%;
  }
}

input[type='radio'] + label .fas {
  display: none;
}

input[type='radio'] + label .fal {
  color: $info;
}

input[type='radio']:checked + label .fas {
  display: block;
  color: $info;
}

_:-moz-tree-row(hover) {
  padding-top: 6px;
}

.form {
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='range'],
  textarea {
    width: 100%;
  }
}

.itemFull {
  width: 100%;
}

.form input.text,
.form textarea {
  box-sizing: border-box;
  border: 1px solid $gray-500;
  border-radius: 0;
  -webkit-appearance: none;
}

.form fieldset {
  position: relative;
  margin: 0;
  padding: 15px 0;
  border: none;
}

.form fieldset.cleanFieldset {
  padding: 0;
}

.form fieldset h2 {
  clear: none;
  text-transform: none;
}

.form label {
  display: inline-block;
  cursor: pointer;
}

.form textarea {
  width: 100%;
  padding: 12px 11px;
  border: 1px solid #a5acb2;
  color: $dark;
  overflow: auto;
  resize: vertical;
}

.form {
  :-ms-input-placeholder {
    color: $gray-450;
  }
}

.actionText {
  box-sizing: border-box;
  float: left;
  height: 36px;
  padding: 2px 5px 2px 10px;
  border: 1px solid $gray-500;
  border-radius: 0;
  font-size: 1em;
  -webkit-appearance: none;
}
