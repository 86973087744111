@import '@anwalt.de/design-system/scss/definitions';

@mixin primary-larger-body-font {
  font-size: $font-size-base * 1.125;
  line-height: $font-size-base * 1.5;
}

@mixin primary-body-font {
  font-size: $font-size-base;
  line-height: $line-height-base;
}

@mixin secondary-body-font {
  font-size: $font-size-sm;
  line-height: 1.25rem;
}

@mixin link-dark {
  color: $dark;
  font-weight: $font-weight-normal;

  &:hover {
    color: $dark;
    font-weight: $font-weight-normal;
  }
}

@mixin link-plain {
  background: inherit;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;

  &:hover {
    font-weight: inherit;
  }
}

@mixin break-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin heading-medium {
  font-size: 22px;
  font-weight: $font-weight-bold;
  line-height: 28px;
  text-transform: none;
}
